import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";

import moment from "moment";

import coreConstant from "../coreConstant";

import {
  coreService,
  registrationService,
  paymentService,
} from "../axiosInstance";

const { REACT_APP_ENCRYPT_KEY, REACT_APP_UI_ENV, REACT_APP_ENV } = process.env;

export const concatArrays = (obj) => {
  const concatedArray = [];
  Object.values(obj).forEach((element) => {
    element.forEach((item) => {
      concatedArray.push(item);
    });
  });
  return concatedArray;
};

export const convertTimetoDateTime = (tm) => {
  return `${moment(new Date()).format(coreConstant.convertDateFormat)} ${tm}`;
};

export const redirectLogin = (responseURL) => {
  if (!REACT_APP_UI_ENV) {
    if (responseURL) {
      const url = new URL(responseURL);
      if (url.search.includes("next") && url.pathname.includes("login")) {
        localStorage.setItem("next", window.location.pathname);
        if (REACT_APP_ENV === "development") {
          window.location.href = "/login/";
        } else {
          window.location.href = `${process.env.REACT_APP_REGISTRATION_SERVICE}/login/?next=${window.location.pathname}`;
        }
      }
    }
  }
};

export const decryptData = (payload) => {
  const decryptedString = CryptoAES.decrypt(
    payload,
    REACT_APP_ENCRYPT_KEY
  ).toString(CryptoENC);
  try {
    return JSON.parse(decryptedString);
  } catch {
    return decryptedString;
  }
};

export const encryptData = (payload) => {
  if (typeof payload !== "string") {
    payload = JSON.stringify(payload);
  }
  return CryptoAES.encrypt(payload, REACT_APP_ENCRYPT_KEY).toString();
};

// export const searchClaim = async (value) => {
//   try {
//     let res = await registrationService.post(coreConstant.claimSearchEndPoint, {
//       data: encryptData({ q: value }),
//     });
//     return res.data.data;
//   } catch {
//     return [];
//   }
// };

export const searchClaim = async (value) => {
  let payload = {};
  payload["search"] = value;
  try {
    let res = await registrationService.get(coreConstant.claimSearchEndPoint, {
      params: payload,
    });
    return res.data;
  } catch {
    return [];
  }
};

export const fetchPincode = async (city, state) => {
  try {
    const searchPincodeRes = await coreService.get(
      coreConstant.fetchPincodeEndPoint,
      {
        params: { city: city, state: state },
      }
    );
    return searchPincodeRes.data.data;
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchCity = async (city) => {
  try {
    const searchCityRes = await coreService.get(
      coreConstant.searchCityEndPoint,
      {
        params: { city: city },
      }
    );
    return searchCityRes.data.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

// Call Vehicle Make Api
export const vehicleMake = async () => {
  try {
    const vehicleMakeRes = await registrationService.get(
      coreConstant.vehicleMake
    );
    return vehicleMakeRes.data.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const convertTimetoString = (tm) => {
  let d = new Date();

  let [hours, minutes, seconds] = tm.split(":");

  d.setHours(+hours);
  d.setMinutes(minutes);
  d.setSeconds(seconds);

  return d.toString();
};

// Get api for Autocomplete pincode
export const getAutocompletePincode = async (pincode) => {
  try {
    const pincodeRes = await coreService.get(coreConstant.autocompletePincode, {
      params: { pincode: pincode },
    });
    return pincodeRes;
  } catch (e) {
    console.log(e);
    return false;
  }
};

// Validate pincode
export const validateAutocompletePincode = async (pincode) => {
  try {
    const validatePincodeRes = await coreService.post(
      coreConstant.validateAutocompletePincode,
      { pincode: pincode }
    );
    return validatePincodeRes.data.data;
  } catch (e) {
    console.log(e);
    return false;
  }
};

// Call fileToBase64 function to convert file to Base64 with argument (File Input)
export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (error) => reject(error);
  });

export const parseFileInputToObj = async (document_name, file) => {
  return {
    document_name: document_name,
    file_type: file.type,
    file_size: file.size,
    file_name: file.name,
    file: await fileToBase64(file),
    is_uploaded: false,
    is_upload: true,
    is_deleted: false,
  };
};

export function isValidDate(dateString) {
  // Check if the date is in the format YYYY-MM-DD using a regular expression
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (!regex.test(dateString)) {
    return false;
  }

  // Parse the date parts to integers
  const parts = dateString.split("-");
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed
  const day = parseInt(parts[2], 10);

  // Create a new date object
  const date = new Date(year, month, day);

  // Check if the date is valid by comparing the input date to the date object created
  if (
    date.getFullYear() !== year ||
    date.getMonth() !== month ||
    date.getDate() !== day
  ) {
    return false;
  }

  return true;
}

export function validateDateRange(dateString, minDate, maxDate) {
  console.log("date string", dateString);
  console.log("minDate", minDate);
  console.log("maxDate", maxDate);

  const selected_date = new Date(dateString);
  const min_date = new Date(minDate);
  const max_date = maxDate ? new Date(maxDate) : null;

  // Validate if selectedDate is within range
  if (
    selected_date >= min_date &&
    (max_date === null || selected_date <= maxDate)
  ) {
    console.log("Valid date");
    return true;
  }
  console.log("Date is out of range");

  return false;
}

export async function checkIfClaimLocked(claim) {
  try {
    const claimLockRes = await paymentService.get(
      coreConstant.checkClaimLock.replace("<claimID>", claim)
    );
    return claimLockRes.data.data;
  } catch (e) {
    console.log(e);
    return {
      is_claim_locked: false,
      message:
        "Temporary issue with SAP communication. Please try again shortly..!",
    };
  }
}
